import { FaGithub } from 'react-icons/fa6';
import { FaBluesky } from "react-icons/fa6";

import config from './config';

const data = [
  {
    link: config.github,
    label: 'Github',
    icon: <FaGithub/>,
    color: "white",
    size:"3em",
  },
    {
    link: config.bluesky,
    icon: <FaBluesky/>,
    color: "white",
    size:"3em",
  }
];

export default data;